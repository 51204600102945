const routes = [
  {
    path: "",
    component: () => import(/* webpackChunkName: "default-[request]" */ "@/layouts/panel/index.vue"),
    children: [
      // admin & employee routes
      // --- //
      // => /dashboard

      {
        path: "/dashboard",
        name: "داشبورد",
        component: () => import(/* webpackChunkName: "dashboard" */ `@/views/admin/dashboard/Dashboard.vue`),
        meta: {
          types: ["authorized"],
        },
      },
      // => /users
      {
        path: "/users",
        name: "کاربران",
        component: () => import(/* webpackChunkName: "users" */ "@/views/admin/dashboard/users/users.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      // => /users/groups
      {
        path: "/users/groups",
        name: "گروه های کاربری",
        component: () =>
          import(/* webpackChunkName: "userGroups" */ "@/views/admin/dashboard/users/groups/userGroups.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      // => /users/groups/addForm
      {
        path: "/users/groups/addForm",
        name: "افزودن گروه کاربری",
        component: () =>
          import(/* webpackChunkName: "userGroups addform" */ "@/views/admin/dashboard/users/groups/addForm.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      // => /users/groups/editForm
      {
        path: "/users/groups/editForm/:id",
        name: "ویرایش گروه کاربری",
        component: () =>
          import(/* webpackChunkName: "userGroups addform" */ "@/views/admin/dashboard/users/groups/editForm.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /users/customers
      {
        path: "/users/customers",
        name: "مشتریان",
        component: () =>
          import(/* webpackChunkName: "customers" */ "@/views/admin/dashboard/users/customers/customers.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      // => /users/customers/addForm
      {
        path: "/users/customers/addForm",
        name: "افزودن مشتری",
        component: () =>
          import(/* webpackChunkName: "customers addForm" */ "@/views/admin/dashboard/users/customers/addForm.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      // => /users/customers/editForm/:id
      {
        path: "/users/customers/editForm/:id",
        name: "ویرایش مشتری",
        component: () =>
          import(/* webpackChunkName: "customers editForm" */ "@/views/admin/dashboard/users/customers/editForm.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      // => /users/customers/:id/details
      {
        path: "/users/customers/:id/details",
        name: "جزئیات مشتری",
        component: () =>
          import(
            /* webpackChunkName: "customers-details" */ "@/views/admin/dashboard/users/customers/customer/details.vue"
          ),
        meta: {
          types: ["authorized"],
        },
      },
      // => /users/customers/:id/wallet
      {
        path: "/users/customers/:id/wallet/",
        name: "سوابق مشتری",
        component: () =>
          import(
            /* webpackChunkName: "customers-wallet" */ "@/views/admin/dashboard/users/customers/wallet/wallet.vue"
          ),
        meta: {
          types: ["authorized"],
        },
      },

      // => /users/customers/:id/wallet/deposit/:depositId/details
      {
        path: "/users/customers/:id/wallet/deposit/:depositId/details",
        name: "جزئیات واریز",
        component: () =>
          import(
            /* webpackChunkName: "customers-wallet" */ "@/views/admin/dashboard/users/customers/wallet/depositDetails.vue"
          ),
        meta: {
          types: ["authorized"],
        },
        alias: "/employee/users/customers/:id/wallet/deposit/:depositId/details",
      },

      // => /users/customers/:id/wallet/addDepositForm
      {
        path: "/users/customers/:id/wallet/addDepositForm",
        name: "فرم واریز جدید",
        component: () =>
          import(
            /* webpackChunkName: "customers-wallet-addDepositForm" */ "@/views/admin/dashboard/users/customers/wallet/addDepositForm.vue"
          ),
        meta: {
          types: ["authorized"],
        },
        alias: "/employee/users/customers/:id/wallet/addDepositForm",
      },

      // => /users/customers/:id/wallet/withdraw/:withdrawId/details
      {
        path: "/users/customers/:id/wallet/withdraw/:withdrawId/details",
        name: "جزئیات برداشت",
        component: () =>
          import(
            /* webpackChunkName: "customers-wallet" */ "@/views/admin/dashboard/users/customers/wallet/withdrawDetails.vue"
          ),
        meta: {
          types: ["authorized"],
        },
      },

      // => /users/customers/:id/wallet/addWithdrawForm
      {
        path: "/users/customers/:id/wallet/addWithdrawForm",
        name: "فرم برداشت جدید",
        component: () =>
          import(
            /* webpackChunkName: "customers-wallet-addWithdrawForm" */ "@/views/admin/dashboard/users/customers/wallet/addWithdrawForm.vue"
          ),
        meta: {
          types: ["authorized"],
        },
      },

      // => /users/levels
      {
        path: "/users/levels",
        name: "سطوح احراز هویت",
        component: () => import(/* webpackChunkName: "levels" */ "@/views/admin/dashboard/users/levels/levels.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      // => /users/levels/addForm
      {
        path: "/users/levels/addForm",
        name: "ایجاد سطح احراز هویت جدید",
        component: () => import(/* webpackChunkName: "levels" */ "@/views/admin/dashboard/users/levels/addForm.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      // => /users/levels/:id/editForm
      {
        path: "/users/levels/:id/editForm",
        name: "ویرایش سطح احراز هویت",
        component: () => import(/* webpackChunkName: "levels" */ "@/views/admin/dashboard/users/levels/editForm.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      // => /users/customers/:id/details/editProfileForm
      {
        path: "/users/customers/:id/details/editProfileForm",
        name: "وبرایش پروفایل مشتری",
        component: () =>
          import(
            /* webpackChunkName: "customers-details-edit-profile-form" */ "@/views/admin/dashboard/users/customers/customer/editProfileForm.vue"
          ),
        meta: {
          types: ["authorized"],
        },
      },

      // => /users/employees
      {
        path: "/users/employees",
        name: "کارمندان",
        component: () =>
          import(/* webpackChunkName: "employees" */ "@/views/admin/dashboard/users/employees/employees.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      // => /users/employees/addForm
      {
        path: "/users/employees/addForm",
        name: "افزودن کارمند",
        component: () =>
          import(/* webpackChunkName: "employees addForm" */ "@/views/admin/dashboard/users/employees/addForm.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      // => /users/employees/editForm/:id
      {
        path: "/users/employees/editForm/:id",
        name: "ویرایش کارمند",
        component: () =>
          import(/* webpackChunkName: "employees editForm" */ "@/views/admin/dashboard/users/employees/editForm.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      // => /users/employees/editRoleForm/:id
      {
        path: "/users/employees/editRoleForm/:id",
        name: "ویرایش نقش های کارمند",
        component: () =>
          import(
            /* webpackChunkName: "employees editRoleForm" */ "@/views/admin/dashboard/users/employees/editRoleForm.vue"
          ),
        meta: {
          types: ["authorized"],
        },
      },
      // => /settings/application
      {
        path: "/settings/application",
        name: "تنظیمات صرافی",
        component: () =>
          import(
            /* webpackChunkName: "settings-application" */ "@/views/admin/dashboard/settings/applicationSetting.vue"
          ),
        meta: {
          types: ["authorized"],
        },
      },
      // => /settings/price
      {
        path: "/settings/price",
        name: "فرمول قیمت",
        component: () =>
          import(/* webpackChunkName: "settings-price" */ "@/views/admin/dashboard/settings/priceSetting.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      // => /settings/gateways
      {
        path: "/settings/gateways",
        name: "درگاه های بانکی",
        component: () =>
          import(/* webpackChunkName: "settings-gateways" */ "@/views/admin/dashboard/settings/gateways.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      // => /coins
      {
        path: "/coins",
        name: "ارز ها",
        component: () => import(/* webpackChunkName: "coins" */ `@/views/admin/dashboard/coins/coinList.vue`),
        meta: {
          types: ["authorized"],
        },
      },
      // => /coins/addForm
      {
        path: "/coins/addForm",
        name: "فرم ثبت ارز جدید",
        component: () => import(/* webpackChunkName: "addForm" */ `@/views/admin/dashboard/coins/addForm.vue`),
        meta: {
          types: ["authorized"],
        },
      },
      // => /coins/editForm/:id
      {
        path: "/coins/editForm/:id",
        name: "فرم ویرایش ارز",
        component: () => import(/* webpackChunkName: "editForm" */ `@/views/admin/dashboard/coins/editForm.vue`),
        meta: {
          types: ["authorized"],
        },
      },
      // => /coins/:id/networks
      {
        path: "/coins/:id/networks",
        name: "شبکه ها",
        component: () =>
          import(/* webpackChunkName: "networks" */ `@/views/admin/dashboard/coins/networks/networkList.vue`),
        meta: {
          types: ["authorized"],
        },
      },
      // => /coins/:id/networks
      {
        path: "/coins/:id/networks/addForm",
        name: "فرم افزودن شبکه",
        component: () => import(/* webpackChunkName: "addForm" */ `@/views/admin/dashboard/coins/networks/addForm.vue`),
        meta: {
          types: ["authorized"],
        },
      },
      // => /coins/:id/networks/editForm/:networkId
      {
        path: "/coins/:id/networks/editForm/:networkId",
        name: "فرم ویرایش شبکه",
        component: () =>
          import(/* webpackChunkName: "editForm" */ `@/views/admin/dashboard/coins/networks/editForm.vue`),
        meta: {
          types: ["authorized"],
        },
      },
      // => /coins/:id/priceSetting
      {
        path: "/coins/:id/priceSetting",
        name: "تنظیمات فرمول قیمت ارز",
        component: () =>
          import(
            /* webpackChunkName: "price - setting" */ `@/views/admin/dashboard/coins/priceSetting/priceSetting.vue`
          ),
        meta: {
          types: ["authorized"],
        },
      },

      // => /coins/:id/networks/helpMessages/:networkId
      {
        path: "/coins/:id/networks/helpMessages/:networkId",
        name: "متون راهنمای شبکه",
        component: () =>
          import(
            /* webpackChunkName: "help massages" */ `@/views/admin/dashboard/coins/networks/helpMessages/helpMessages.vue`
          ),
        meta: {
          types: ["authorized"],
        },
      },

      // => /coins/:id/financesetting
      {
        path: "/coins/:id/financesetting",
        name: "تنظیمات مالی ارز",
        component: () =>
          import(
            /* webpackChunkName: "coin finance setting" */ `@/views/admin/dashboard/coins/financeSetting/coinFinancialSetting.vue`
          ),
        meta: {
          types: ["authorized"],
        },
      },

      // => /wallet/deposit
      {
        path: "/wallet/deposit",
        name: "واریزی ها",
        component: () => import(/* webpackChunkName: "deposit" */ `@/views/admin/dashboard/wallet/deposit.vue`),
        meta: {
          types: ["authorized"],
        },
      },
      // => /wallet/withdraw
      {
        path: "/wallet/withdraw",
        name: "برداشت ها",
        component: () => import(/* webpackChunkName: "withdraw" */ `@/views/admin/dashboard/wallet/withdraw.vue`),
        meta: {
          types: ["authorized"],
        },
      },
      // => /wallet/order
      {
        path: "/wallet/order",
        name: "سفارشات",
        component: () => import(/* webpackChunkName: "order" */ `@/views/admin/dashboard/wallet/order.vue`),
        meta: {
          types: ["authorized"],
        },
      },
      // => /wallet/preorder
      {
        path: "/wallet/preorder",
        name: "سفارشات سفارش گذاری",
        component: () => import(/* webpackChunkName: "preorder" */ `@/views/admin/dashboard/wallet/preOrder.vue`),
        meta: {
          types: ["authorized"],
        },
      },
      // => /wallet/p2porder
      {
        path: "/wallet/p2pOrder",
        name: "سفارشات بازار حرفه ای",
        component: () => import(/* webpackChunkName: "preorder" */ `@/views/admin/dashboard/wallet/p2pOrder.vue`),
        meta: {
          types: ["authorized"],
        },
      },
      // => /wallet/stableCoinP2pOrder
      {
        path: "/wallet/stableCoinP2pOrder",
        name: "سفارشات p2p ریالی",
        component: () => import(/* webpackChunkName: "order" */ `@/views/admin/dashboard/wallet/stableCoinP2pOrder.vue`),
        meta: {
          types: ["authorized"],
        },
      },
      // => /wallet/transaction
      {
        path: "/wallet/transaction",
        name: "تراکنش ها",
        component: () => import(/* webpackChunkName: "transaction" */ `@/views/admin/dashboard/wallet/transaction.vue`),
        meta: {
          types: ["authorized"],
        },
      },

      // => /tasks/withdraw
      {
        path: "/tasks/withdraw",
        name: "تسک های برداشت",
        component: () =>
          import(/* webpackChunkName: "withdraw-tasks" */ `@/views/admin/dashboard/tasks/withdrawTasks.vue`),
        meta: {
          types: ["authorized"],
        },
      },

      // => /tasks/trade
      {
        path: "/tasks/trade",
        name: "تسک های ترید",
        component: () => import(/* webpackChunkName: "trade-tasks" */ `@/views/admin/dashboard/tasks/tradeTasks.vue`),
        meta: {
          types: ["authorized"],
        },
      },

      // => /tasks/preOrderTrade
      {
        path: "/tasks/preOrderTrade",
        name: "تسک های سفارش گذاری",
        component: () => import(/* webpackChunkName: "preorder-trade-tasks" */ `@/views/admin/dashboard/tasks/preOrderTradeTasks.vue`),
        meta: {
          types: ["authorized"],
        },
      },

        // => /tasks/p2pOrderTrade
        {
          path: "/tasks/p2pOrderTrade",
          name: "تسک های بازار حرفه ای",
          component: () => import(/* webpackChunkName: "preorder-trade-tasks" */ `@/views/admin/dashboard/tasks/p2pOrderTradeTasks.vue`),
          meta: {
            types: ["authorized"],
          },
        },
  

      // => /tasks/deposit
      {
        path: "/tasks/deposit",
        name: "تسک های واریز",
        component: () =>
          import(/* webpackChunkName: "withdraw-tasks" */ `@/views/admin/dashboard/tasks/depositTasks.vue`),
        meta: {
          types: ["authorized"],
        },
      },
      // => /changePassword
      {
        path: "/changePassword",
        name: "تنظیمات امنیتی",
        component: () => import(/* webpackChunkName: "change password" */ "@/views/commons/changePassword.vue"),
        meta: {
          roles: ["admin", "customer", "employee"],
        },
      },

      // => /reports/balances
      {
        path: "/reports/balances",
        name: "موجودی سایت",
        component: () => import(/* webpackChunkName: "balances" */ "@/views/admin/dashboard/reports/reportBalances.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /reports/balance/detail/:coinId
      {
        path: "/reports/balance/detail/:coinId",
        name: "جزئیات موجودی",
        component: () => import(/* webpackChunkName: "customerBalances" */ "@/views/admin/dashboard/reports/reportBalanceDetail.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /reports/dailyOrders
      {
        path: "/reports/dailyOrders",
        name: "سفارشات روزانه",
        component: () =>
          import(/* webpackChunkName: "dailyOrders" */ "@/views/admin/dashboard/reports/reportDailyOrders.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /reports/profit
      {
        path: "/reports/profit",
        name: "سود سفارشات OTC",
        component: () =>
          import(/* webpackChunkName: "profit" */ "@/views/admin/dashboard/reports/profit.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /reports/dailyProfit
      {
        path: "/reports/dailyProfit",
        name: "سود روزانه سفارشات OTC",
        component: () =>
          import(/* webpackChunkName: "dailyProfit" */ "@/views/admin/dashboard/reports/dailyProfit.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /reports/dailyFeeIncomes
      {
        path: "/reports/dailyFeeIncomes",
        name: "درآمد روزانه از کارمزد سفارشات P2P",
        component: () =>
          import(/* webpackChunkName: "dailyFeeIncomes" */ "@/views/admin/dashboard/reports/reportDailyFeeIncomes.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      

      // => /widgets/promotions
      {
        path: "/widgets/promotions",
        name: "پروموشن",
        component: () =>
          import(/* webpackChunkName: "promotions" */ "@/views/admin/dashboard/promotions/promotions.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /widgets/promotions/add
      {
        path: "/widgets/promotions/add",
        name: "افزودن پروموشن",
        component: () =>
          import(/* webpackChunkName: "promotion add" */ "@/views/admin/dashboard/promotions/addPromotion.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /helpMessages
      {
        path: "/helpMessages",
        name: "متون راهنما",
        component: () =>
          import(/* webpackChunkName: "help messages" */ "@/views/admin/dashboard/helpMessages/helpMessages.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /helpMessages/add
      {
        path: "/helpMessages/add",
        name: "افزودن متن راهنما",
        component: () =>
          import(/* webpackChunkName: "help messages add" */ "@/views/admin/dashboard/helpMessages/addHelpMessage.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /helpMessages/edit
      {
        path: "/helpMessages/edit/:id",
        name: "ویرایش متن راهنما",
        component: () =>
          import(
            /* webpackChunkName: "help messages edit" */ "@/views/admin/dashboard/helpMessages/editHelpMessage.vue"
          ),
        meta: {
          types: ["authorized"],
        },
      },

      // => /jobs/identities
      {
        path: "/jobs/identities",
        name: "بررسی مدارک",
        component: () =>
          import(/* webpackChunkName: "jobs - identities - list" */ "@/views/admin/dashboard/jobs/identities/list.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /jobs/bankaccounts
      {
        path: "/jobs/bankaccounts",
        name: "بررسی حساب های بانکی",
        component: () =>
          import(
            /* webpackChunkName: "jobs - bankaccounts - list" */ "@/views/admin/dashboard/jobs/bankaccounts/list.vue"
          ),
        meta: {
          types: ["authorized"],
        },
      },

      // => /jobs/profiles
      {
        path: "/jobs/profiles",
        name: "بررسی پروفایل",
        component: () =>
          import(/* webpackChunkName: "jobs - profiles - list" */ "@/views/admin/dashboard/jobs/profiles/list.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /staking/stakeplans
      {
        path: "/staking/stakeplans",
        name: "پلن های سرمایه گذاری",
        component: () =>
          import(/* webpackChunkName: "staking - stakeplans - list" */ "@/views/admin/dashboard/stakeplans/stakePlanList.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /staking/stakeplans/add
      {
        path: "/staking/stakeplans/add",
        name: "ثبت پلن سرمایه گذاری جدید",
        component: () =>
          import(/* webpackChunkName: "staking - stakeplans - add" */ "@/views/admin/dashboard/stakeplans/addStakePlan.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /staking/stakeplans/edit:id
      {
        path: "/staking/stakeplans/edit/:id",
        name: "ویرایش پلن سرمایه گذاری",
        component: () =>
          import(/* webpackChunkName: "staking - stakeplans - edit" */ "@/views/admin/dashboard/stakeplans/editStakePlan.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /staking/stakeplans/edit:id
      {
        path: "/staking/stakeplans/editHelpMessage/:id",
        name: "ویرایش قوانین سرمایه گذاری",
        component: () =>
          import(/* webpackChunkName: "staking - stakeplans - editHelpMessage" */ "@/views/admin/dashboard/stakeplans/editStakePlanHelpMessage.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /staking/stakes
      {
        path: "/staking/stakes",
        name: "سرمایه گذاری ها",
        component: () =>
          import(/* webpackChunkName: "staking - stakes - list" */ "@/views/admin/dashboard/stakes/list.vue"),
        meta: {
          types: ["authorized"],
        },
      },


      // => /fanclub/lottery
      {
        path: "/fanclub/lottery",
        name: "قرعه کشی ها",
        component: () =>
          import(/* webpackChunkName: "fanclub - lottery - list" */ "@/views/admin/dashboard/lottery/listLottery.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /fanclub/lottery/add
      {
        path: "/fanclub/lottery/add",
        name: "ثبت قرعه کشی جدید",
        component: () =>
          import(/* webpackChunkName: "fanclub - lottery - add d" */ "@/views/admin/dashboard/lottery/addLottery.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /fanclub/lottery/edit/:id
      {
        path: "/fanclub/lottery/edit/:id",
        name: "ویرایش قرعه کشی",
        component: () =>
          import(/* webpackChunkName: "fanclub - lottery - edit" */ "@/views/admin/dashboard/lottery/editLottery.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /fanclub/lottery/participants/:id
      {
        path: "/fanclub/lottery/participants/:id",
        name: "شرکت کنندگان قرعه کشی",
        component: () =>
          import(/* webpackChunkName: "fanclub - lottery - participates" */ "@/views/admin/dashboard/lottery/participants.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /fanclub/festival
      {
        path: "/fanclub/festival",
        name: "جشنواره ها",
        component: () =>
          import(/* webpackChunkName: "fanclub - festival - list" */ "@/views/admin/dashboard/festival/listFestival.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /fanclub/festival/add
      {
        path: "/fanclub/festival/add",
        name: "ثبت جشنواره جدید",
        component: () =>
          import(/* webpackChunkName: "fanclub - festival - add" */ "@/views/admin/dashboard/festival/addFestival.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      
      // => /fanclub/festival/edit/:id
      {
        path: "/fanclub/festival/edit/:id",
        name: "ویرایش جشنواره",
        component: () =>
          import(/* webpackChunkName: "fanclub - festival - edit" */ "@/views/admin/dashboard/festival/editFestival.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /ticketing/ticketDepartment
      {
        path: "/ticketing/ticketDepartment",
        name: "دپارتمان ها",
        component: () =>
          import(/* webpackChunkName: "ticketing - ticketDepartment - list" */ "@/views/admin/dashboard/ticketDepartments/ticketDepartmentList.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /ticketing/ticketDepartment/add
      {
        path: "/ticketing/ticketDepartment/add",
        name: "ثبت دپارتمان جدید",
        component: () =>
          import(/* webpackChunkName: "ticketing - ticketDepartment - add" */ "@/views/admin/dashboard/ticketDepartments/addTicketDepartment.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /ticketing/ticketDepartment/edit/:id
      {
        path: "/ticketing/ticketDepartment/edit/:id",
        name: "ویرایش دپارتمان",
        component: () =>
          import(/* webpackChunkName: "ticketing - ticketDepartment - edit" */ "@/views/admin/dashboard/ticketDepartments/editTicketDepartment.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /ticketing/ticket
      {
        path: "/ticketing/ticket",
        name: "سوابق تیکت ها",
        component: () =>
          import(/* webpackChunkName: "ticketing - ticket - list" */ "@/views/admin/dashboard/tickets/ticketList.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /ticketing/ticket/add/:customerId
      {
        path: "/ticketing/ticket/add/:customerId",
        name: "ثبت تیکت جدید",
        component: () =>
          import(/* webpackChunkName: "ticketing - ticket - add" */ "@/views/admin/dashboard/tickets/addTicket.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /ticketing/ticket/detail/:id
      {
        path: "/ticketing/ticket/detail/:id",
        name: "شرح تیکت",
        component: () =>
          import(/* webpackChunkName: "ticketing - ticket - detail" */ "@/views/admin/dashboard/tickets/ticketDetail.vue"),
        meta: {
          types: ["authorized"],
        },
      },


      // => /suppliment/
      {
        path: "/suppliment/",
        name: "سوابق تامین ارز",
        component: () =>
          import(/* webpackChunkName: "suppliment" */ "@/views/admin/dashboard/suppliment/supplimentList.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      // => /suppliment/add
      {
        path: "/suppliment/add",
        name: "ثبت تامین ارز جدید",
        component: () =>
          import(/* webpackChunkName: "suppliment - add" */ "@/views/admin/dashboard/suppliment/addSuppliment.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      
      
    ]
  }
];

// ================================================================ Drawer ================================================================
const drawer = [
  {
    title: "داشبورد",
    icon: "mdi-view-dashboard",
    to: `/dashboard`,
  },

  {
    title: "ارز ها",
    icon: "mdi-bitcoin",
    to: `/coins`,
  },

  {
    title: "کاربران",
    icon: "mdi-account-group",
    to: "/users",

    children: [
      {
        title: "گروه های کاربری",
        icon: "mdi-circle-small",
        to: `/users/groups`,
      },
      {
        title: "مشتریان",
        icon: "mdi-circle-small",
        to: `/users/customers`,
      },
      {
        title: "سطوح احراز هویت",
        icon: "mdi-circle-small",
        to: `/users/levels`,
      },
      {
        title: "کارمندان",
        icon: "mdi-circle-small",
        to: `/users/employees`,
      },
    ],
  },

  {
    title: "پشتیبانی تیکت",
    icon: "mdi-face-agent",
    to: `/ticketing`,
    children: [
      {
        title: "دپارتمان ها",
        icon: "mdi-circle-small",
        to: `/ticketing/ticketDepartment/`,
      },
      {
        title: "سوابق تیکت ها",
        icon: "mdi-circle-small",
        to: `/ticketing/ticket/`,
      },
    ]
  },

  {
    title: "سرمایه گذاری",
    icon: "mdi-currency-usd",
    to: `/staking`,
    children: [
      {
        title: "پلن های سرمایه گذاری",
        icon: "mdi-circle-small",
        to: `/staking/stakeplans/`,
      },
      {
        title: "سرمایه گذاری ها",
        icon: "mdi-circle-small",
        to: `/staking/stakes/`,
      },
    ]
  },

  {
    title: "باشگاه مشتریان",
    icon: "mdi-account-group",
    to: `/fanclub`,
    children: [
      {
        title: "قرعه کشی ها",
        icon: "mdi-circle-small",
        to: `/fanclub/lottery/`,
      },
      {
        title: "جشنواره ها",
        icon: "mdi-circle-small",
        to: `/fanclub/festival/`,
      },
    ]
  },

  {
    title: "سوابق",
    icon: "mdi-clipboard-text-clock-outline",
    to: "/wallet",
    children: [
      {
        title: "سفارشات (OTC)",
        icon: "mdi-circle-small",
        to: `/wallet/order`,
      },
      {
        title: "سفارشات سفارش گذاری",
        icon: "mdi-circle-small",
        to: `/wallet/preorder`,
      },
      {
        title: "سفارشات بازار حرفه ای (P2P)",
        icon: "mdi-circle-small",
        to: `/wallet/p2pOrder`,
      },
      {
        title: "سفارشات بازار P2P ریالی",
        icon: "mdi-circle-small",
        to: `/wallet/stableCoinP2pOrder`,
      },
      {
        title: "واریز",
        icon: "mdi-circle-small",
        to: `/wallet/deposit`,
      },
      {
        title: "برداشت",
        icon: "mdi-circle-small",
        to: `/wallet/withdraw`,
      },
      {
        title: "تراکنش",
        icon: "mdi-circle-small",
        to: `/wallet/transaction`,
      },
    ],
  },

  {
    title: "تنظیمات",
    icon: "mdi-cog",
    to: "/settings",

    children: [
      {
        title: "صرافی",
        icon: "mdi-circle-small",
        to: `/settings/application`,
      },
      {
        title: "فرمول قیمت",
        icon: "mdi-circle-small",
        to: `/settings/price`,
      },
      {
        title: "درگاه های بانکی",
        icon: "mdi-circle-small",
        to: `/settings/gateways`,
      },
    ],
  },

  {
    title: "کارتابل",
    icon: "mdi-beaker-question-outline",
    to: "/jobs",
    children: [
      {
        title: "بررسی مدارک",
        icon: "mdi-circle-small",
        to: `/jobs/identities`,
      },
      {
        title: "بررسی حساب های بانکی",
        icon: "mdi-circle-small",
        to: `/jobs/bankaccounts`,
      },
      {
        title: "بررسی پروفایل",
        icon: "mdi-circle-small",
        to: `/jobs/profiles`,
      },
    ],
  },

  {
    title: "تسک ها",
    icon: "mdi-calendar-check-outline",
    to: "/settings",

    children: [
      {
        title: "تسک های واریز",
        icon: "mdi-circle-small",
        to: `/tasks/deposit`,
      },
      {
        title: "تسک های برداشت",
        icon: "mdi-circle-small",
        to: `/tasks/withdraw`,
      },
      {
        title: "تسک های ترید",
        icon: "mdi-circle-small",
        to: `/tasks/trade`,
      },
      {
        title: "تسک سفارش گذاری",
        icon: "mdi-circle-small",
        to: `/tasks/preordertrade`,
      },
      {
        title: "تسک بازار حرفه ای",
        icon: "mdi-circle-small",
        to: `/tasks/p2pordertrade`,
      },
    ],
  },

  {
    title: "تامین ارز",
    icon: "mdi-cash",
    to: `/suppliment`,
  },

  {
    title: "گزارشات",
    icon: "mdi-chart-line",
    to: "/settings",

    children: [
      {
        title: "موجودی سایت",
        icon: "mdi-circle-small",
        to: `/reports/balances`,
      },
      {
        title: "سفارشات روزانه",
        icon: "mdi-circle-small",
        to: `/reports/dailyOrders`,
      },
      {
        title: "سود سفارشات OTC",
        icon: "mdi-circle-small",
        to: `/reports/profit`,
      },
      {
        title: "سود روزانه سفارشات OTC",
        icon: "mdi-circle-small",
        to: `/reports/dailyProfit`,
      },
      {
        title: "درآمد کارمزد سفارشات P2P",
        icon: "mdi-circle-small",
        to: `/reports/dailyFeeIncomes`,
      },
    ],
  },

  {
    title: "پروموشن",
    icon: "mdi-panorama-variant-outline",
    to: `/widgets/promotions`,
  },

  {
    title: "متون راهنما",
    icon: "mdi-text-recognition",
    to: `/helpMessages`,
  },
];

export { routes, drawer };
